import type { FC } from 'react'
import { Title, Card, Button } from 'components'
import styles from './Novelty.module.scss'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'hooks/useTypedSelector'
import Spiner from 'components/atom/Spiner'

const Novelty: FC<any> = ({ data }) => {
  const { settingSlice } = useAppSelector(state => state)

  const subtitle = `Інтернет-магазин ${
    settingSlice.data.data?.name || 'no name'
  } — це доступні ціни та широкий асортимент товарів зі щоденним оновленням`

  const renderItems = data.data.map((element: any, index: number) => ( 
    <Card
      key={index}
      id={element.product_id}
      image={element.thumb}
      title={element.name}
      special={element.special}
      rate={element.special}
      reviwers={element.reviwers}
      price={element.price}
      saved={element.in_wishlist}
    />
  ))

  return (
    <div className={styles.wrapper}>
      <Title title='Новинки' subtitle={subtitle} />
      {data.loading ? (
        <Spiner />
      ) : (
        <>
          <div className={styles.wrapper__content}>{renderItems}</div>
          <Link to='/app/categories'>
            <Button className={styles.wrapper__button}>Дивитися усі</Button>
          </Link>
        </>
      )}
    </div>
  )
}

export default Novelty
