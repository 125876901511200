import type { FC } from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { LinkIcon, MenuLeftIcon, MenuRightIcon, PhoneSecondaryIcon } from 'assets'

import styles from './Slider.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'
import { url } from 'inspector'
import { useNavigate, useRoutes } from 'react-router'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

const Slider: FC = () => {
  const { settingSlice } = useAppSelector(state => state)

  const navigate = useNavigate()

  const Item = ({ banner }: any) => {
    return (
      <div className='w-full overflow-hidden'>
        <a
          href={banner?.product?.product_id ? `/product/${banner?.product?.product_id}` : '#'}
          className='cursor-pointer h-[280px] sm:h-[580px] w-full bg-no-repeat bg-cover bg-center'
          style={{ backgroundImage: `url(${banner.thumb})` }}
        >
          {/* <img className='object-cover w-full h-full' src={banner.thumb} alt='Product Image' /> */}
        </a>
        {/* {banner?.product && (
          <a
            href={`/product/${banner?.product?.product_id}`}
            className=' bg-[#ffa800] absolute text-xs sm:text-base left-3 sm:left-6 px-3 py-1 top-[85%]  sm:top-[90%] sm:px-4 sm:py-2 text-center text-white '
          >
            Перейти до товару
          </a>
        )} */}

        {/* <div className={styles.wrapper__container}> */}
        {/* <div className={styles.wrapper__container__description}>
            <h1 dangerouslySetInnerHTML={{ __html: banner?.product?.info.h1 }} className={styles.wrapper__container__title}></h1>

            <p dangerouslySetInnerHTML={{ __html: banner?.product?.info.description }} className={styles.wrapper__container__text}></p>
          </div> */}
        {/* 
          {banner?.product?.product_id && (
            
            <div className={styles.wrapper__container__footer}>
              <a href={`/product/${banner?.product?.product_id}`}>
              <div role='button' className={styles.wrapper__container__button}>
                <a href={`/product/${banner?.product?.product_id}`} className=" bg-[#ffa800] px-4 py-2 text-center text-white ">
                  Перейти до товару
                </a>
              </div>
              </a>
            </div>
          )} */}
        {/* </div> */}
      </div>
    )
  }

  const items = settingSlice.data?.data?.category?.banners?.map((item: any, index: number) => (
    <Item key={index} banner={item} />
  ))

  const CustomNextButton = () => {
    if (items?.length > 0)
      return (
        <div className=' bg-[#ffa800] p-2  right-2 rounded-full absolute top-[45%]'>
          <MenuLeftIcon />
        </div>
      )

    return null
  }

  const CustomPrevButton = () => {
    if (items?.length > 0)
      return (
        <div className=' bg-[#ffa800] p-2 rounded-full left-2 absolute top-[45%]'>
          <MenuRightIcon />
        </div>
      )

    return null
  }

  return (
    <>
      <AliceCarousel
        renderPrevButton={() => <CustomPrevButton />}
        renderNextButton={() => <CustomNextButton />}
        items={items}
        touchMoveDefaultEvents={true}
      />
    </>
  )
}

export default Slider
