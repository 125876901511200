import { useRef, type FC } from 'react'
import styles from './Modal.module.scss'
import { ModalProps } from './type'
import { CSSTransition } from 'react-transition-group'

const Modal: FC<ModalProps> = ({ show, children, onClose }) => {
  const nodeRef = useRef(null)

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={300} classNames='popup' unmountOnExit>

    <div onClick={onClose} className={styles.modal}>
      <CSSTransition in={show} nodeRef={nodeRef} timeout={300} classNames='popup' unmountOnExit>
        <div ref={nodeRef} className={styles.modal__block}>
          {children}
        </div>
      </CSSTransition>
    </div>
    </CSSTransition>
  )
}
{
}
export default Modal
